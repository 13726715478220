<div
  class="plan-bubble"
  [ngClass]="{ clickable: clickable, small: sizeSmall }"
  [class]="organization?.stripeReadableProductId || ''"
  (click)="clickable && onClick()"
>
  @switch (organization?.stripeReadableProductId) {
    @case (StripeProductId.PREMIUM) {
      Premium
    }
    @case (StripeProductId.STANDARD) {
      Standard
    }
    @case (StripeProductId.BASIC) {
      Basis
    }
    @case (StripeProductId.DEACTIVATED) {
      Deaktiviert
    }
    @case (StripeProductId.TESTPHASE) {
      Testphase
    }
  }
  @if (organization?.cancelAtPeriodEnd) {
    <div class="cancel-at-period-end"></div>
    <sfu-svg-icon
      class="cancel-icon"
      src="warning"
      svgClass="very-small"
    ></sfu-svg-icon>
  }
</div>
@if (organization?.cancelAtPeriodEnd && !sizeSmall) {
  <sfa-svg-info-icon
    infoText="Dieses Abonnement wird am Ende der Abrechnungsperiode gelöscht und in ein Gratis Abonnement umgewandelt. Wenn du das verhindern möchtest, kannst du das Abo unter 'Meine Abos' reaktivieren."
  ></sfa-svg-info-icon>
}
