import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { SvgInfoIconComponent } from '../svg-info-icon/svg-info-icon.component';
import { SvgIconComponent } from '../../../../../../../shared/components/svg-icon/svg-icon.component';
import { StripeProductId } from '../../models/models';
import { Organization } from '../../../core/store/organization/organization.model';

@Component({
  selector: 'sfa-stripe-product-chip',
  standalone: true,
  imports: [NgClass, RouterLink, SvgInfoIconComponent, SvgIconComponent],
  templateUrl: './stripe-product-chip.component.html',
  styleUrl: './stripe-product-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeProductChipComponent {
  protected readonly StripeProductId = StripeProductId;

  @Input() organization: Organization | undefined;
  @Input() clickable = false;
  @Input() sizeSmall = false;

  private router = inject(Router);

  onClick() {
    this.router.navigate(['/account/products']);
  }
}
